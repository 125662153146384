export default () => {
  const fields = document.querySelector(`[data-behaviour="passport-fields"]`);
  const button = document.querySelector(`[data-behaviour="passport-button"]`);
  const hiddenField = document.querySelector(
    `[data-behaviour="passport-hidden-field"]`
  );

  const isAddingPassport = () => {
    hiddenField.value === '1';
  };

  const toggleHiddenField = () => {
    if (isAddingPassport()) {
      hiddenField.value === '';
    } else {
      hiddenField.value === '1';
    }
  };

  const updateUI = () => {
    fields.classList.toggle('hidden');
  };

  const copyChange = () => {
    if (button.classList.contains('copy-change')) {
      button.classList.remove('copy-change');
      button.innerHTML = 'Add Passport Details Now';
    } else {
      button.classList.add('copy-change');
      button.innerHTML = 'Add Passport Details Later';
    }
  };

  if (button) {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      toggleHiddenField();
      updateUI();
      button.toggle(copyChange());
    });
  }
};
