export default () => {
  const form = document.querySelector(`[data-behaviour="team-form"]`);
  let selectBox = document.querySelector('select');
  if(selectBox) {

    let options = selectBox.querySelectorAll('option');
    const submit = document.querySelector('.team-form-submit');

    if (form) {
      form.addEventListener('change', () => {
        submit.click();
      });
    }

    if (options.length < 2) {
      submit.style.display = 'block';
    }
  }
};
