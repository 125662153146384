export default () => {
  const POLL_INTERVAL = 5000;

  const progressPoller = document.querySelector(
    `[data-behaviour="progress-poller"]`
  );

  if (progressPoller) {
    const url = progressPoller.dataset.pollUrl;

    async function poll() {
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (response.status == 200) {
          window.location.replace(data.redirect_url);
        } else {
          setTimeout(poll, POLL_INTERVAL);
        }
        return data;
      } catch (error) {
        console.log('error', error);
      }
    }

    poll();
  }
};
