export default () => {
  const preview = document.querySelector(`[data-behaviour="upload-filename"]`);
  const upload = document.getElementById('passport-image');

  if (upload) {
    upload.addEventListener('change', (event) => {
      const value = event.target.value;
      const filename = value.split('\\').slice(-1)[0];
      preview.value = filename;
      // upload.classList.remove('hidden');
    });
  }
};
