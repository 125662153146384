import 'bootstrap.native';
import jquery from 'jquery';

// import AddPaymentForm from './portal/add_payment_form';
// import BuyKitForm from './portal/buy_kit_form';
// import FamilyForm from './portal/family_form';
import FileInput from './portal/file_input';
// import MessageBoard from './portal/message_board';
import PassportToggle from './portal/passport_toggle';
// import PreventFormResubmission from './portal/prevent_form_resubmission';
import ProgressPoller from './portal/progress_poller';
// import Registration from './portal/registration';
// import ScrollEvent from './portal/scroll_event';
// import ScrollToError from './portal/scroll_to_error';
// import TableScroll from './portal/table_scroll';
import TeamForm from './portal/team_form';
// import VenturerForm from './portal/venturer_form';
import ShowHide from './show_hide';
import MedicalDetails from './portal/medical_details';
import TravelHealth from './portal/travel_health';

window.jQuery = jquery;
window.$ = jquery;

// ShowHide
(() => {
  ShowHide();
})();

// // Add Payment Form
// (() => {
//   AddPaymentForm();
// })();

// // Buy Kit Form
// (() => {
//   BuyKitForm();
// })();

// // Family Form
// (() => {
//   FamilyForm();
// })();

// File Input
(() => {
  FileInput();
})();

// // Message Board
// (() => {
//   MessageBoard();
// })();

// Passport Toggle
(() => {
  PassportToggle();
})();

// // Prevent Form Resubmission
// (() => {
//   PreventFormResubmission();
// })();

// ProgressPoller
(() => {
  ProgressPoller();
})();

// // Registration
// (() => {
//   Registration();
// })();

// // ScrollEvent
// (() => {
//   ScrollEvent();
// })();

// // ScrollToError
// (() => {
//   ScrollToError();
// })();

// // TableScroll
// (() => {
//   TableScroll();
// })();

// TeamForm
(() => {
  TeamForm();
})();

// Medical Details
(() => {
  MedicalDetails();
})();

// Travel Health
(() => {
  TravelHealth();
})();

// // VenturerForm
// (() => {
//   VenturerForm();
// })();

// require([
//   'modules/no_paste',
//   'modules/portal/registration',
//   'modules/portal/venturer_form',
//   'modules/portal/family_form',
//   'modules/portal/medical_details',
//   'modules/portal/message_board',
//   'modules/portal/buy_kit_form',
//   'modules/portal/progress_poller',
//   'modules/portal/prevent_form_resubmission',
//   'modules/portal/scroll_event',
//   'modules/lightbox',
//   'modules/portal/add_payment_form',
//   'modules/portal/table_scroll',
//   'modules/portal/team_form',
//   'modules/portal/scroll_to_error',
//   'modules/show_hide'
// ], function() { var i, l; for (i = 0, l = arguments.length; i < l; i++) arguments[i].init(); }
// );

// require([
//   'modules/no_paste',
//   'modules/portal/registration',
//   'modules/portal/venturer_form',
//   'modules/portal/family_form',
//   'modules/portal/medical_details',
//   'modules/portal/message_board',
//   'modules/portal/buy_kit_form',
//   'modules/portal/progress_poller',
//   'modules/portal/prevent_form_resubmission',
//   'modules/portal/scroll_event',
//   'modules/lightbox',
//   'modules/portal/add_payment_form',
//   'modules/portal/table_scroll',
//   'modules/portal/team_form',
//   'modules/portal/scroll_to_error',
//   'modules/show_hide',
// ], function () {
//   $(function () {
//     var portalJS = function () {
//       var init = function () {
//           loadModules();
//         },
//         loadModules = function () {
//           var i, l;
//           for (i = 0, l = arguments.length; i < l; i++) arguments[i].init();
//           return {
//             init: init,
//           };
//         };
//     };
//     portalJS();
//   });
// });
//
