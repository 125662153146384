// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../modules'; // See app/javascript/modules/index.js for all JS
import '../modules/portal';
import '../stylesheets/portal.scss'; // See app/javascript/stylesheets/application.scss for all CMS css

// Images - Imports every image from image folder
function importAll(r) {
  return r.keys().map(r);
}

importAll(require.context('images/.', true, /\.(png|gif|jpe?g|svg)$/));
