// import '@babel/polyfill';
import './pollyfills';
import './load_service_worker';
import './font_observer';
// import 'wck';
import { start as startUjs } from '@rails/ujs';
import lozad from 'lozad';
// import { start as activestorage } from '@rails/activestorage'; // Only add if required
import { EventListener, Cookiebar } from '@morsedigital/morse-utils';
import jquery from 'jquery';

window.jQuery = jquery;
window.$ = jquery;

// import AjaxForm from './ajax_form';
// import Blog from './blog';
// // import Bootstrapper from './bootstrapper';
// import Fader from './fader';
// import FormValidation from './form_validation';
// import Inputs from './inputs';
// import Lightbox from './lightbox';
// import LoadEvent from './load_event';
// import Map from './map';
// import Members from './members';
// import Nav from './nav';
// import NoPaste from './no_paste';
import Parallax from './parallax';
// import ResizeEvent from './resize_event';
// import Retina from './retina';
// import ScrollEvent from './scroll_event';
// import Scrolls from './scrolls';
// import Slider from './slider';
// import Tabs from './tabs';

(() => {
  startUjs(); // Start UJS
  // activestorage(); // Start Activestorage
})();

(() => {
  // Utility functions
  const modules = [
    // AlertClose(),
    // AlertReset(),
    Cookiebar('cookie-prompt'), // Should be id of Cookie prompt DOM Node
    // Modal(),
  ];

  EventListener(modules);
})();

// Lazy load images
(() => {
  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();
})();

// // Ajax Form
// (() => {
//   AjaxForm();
// })();

// // Blog
// (() => {
//   Blog();
// })();

// // Bootstrapper
// (() => {
//   Bootstrapper();
// })();

// // Fader
// (() => {
//   Fader();
// })();

// // FormValidation
// (() => {
//   FormValidation();
// })();

// // Inputs
// (() => {
//   Inputs();
// })();

// // Lightbox
// (() => {
//   Lightbox();
// })();

// // LoadEvent
// (() => {
//   LoadEvent();
// })();

// // Map
// (() => {
//   Map();
// })();

// // Members
// (() => {
//   Members();
// })();

// // Nav
// (() => {
//   Nav();
// })();

// // NoPaste
// (() => {
//   NoPaste();
// })();

// Parallax
(() => {
  Parallax();
})();

// // ResizeEvent
// (() => {
//   ResizeEvent();
// })();

// // Retina
// (() => {
//   Retina();
// })();

// // ScrollEvent
// (() => {
//   ScrollEvent();
// })();

// // Scrolls
// (() => {
//   Scrolls();
// })();

// // ShowHide
// (() => {
//   ShowHide();
// })();

// // Slider
// (() => {
//   Slider();
// })();

// // Tabs
// (() => {
//   Tabs();
// })();

// require([
//   'modules/blog',
//   'modules/nav',
//   'modules/fader',
//   'modules/show_hide',
//   'modules/lightbox',
//   'modules/map',
//   'modules/parallax',
//   'modules/inputs',
//   'modules/scrolls',
//   'modules/form_validation',
//   'modules/ajax_form',
//   'modules/slider',
//   'modules/retina',
//   'modules/tabs',
//   'modules/scroll_event',
//   'modules/load_event',
//   'modules/resize_event',
//   'modules/members',
// ], function (
//   Blog,
//   nav,
//   fader,
//   showHide,
//   lightbox,
//   map,
//   parallax,
//   inputs,
//   scrolls,
//   formValidation,
//   ajaxForm,
//   slider,
//   retina,
//   tabs,
//   scrollEvent,
//   loadEvent,
//   resizeEvent,
//   members
// ) {
//   $(function () {
//     var mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
//       navigator.userAgent
//     );

//     $(function () {
//       var applicationJS = function () {
//         var init = function () {
//             loadModules();
//           },
//           loadModules = function () {
//             Blog.init();
//             nav.init();
//             showHide.init();
//             lightbox.init();
//             if (!mobile) {
//               parallax.init();
//               $('body').addClass('desktop');
//             }
//             scrolls.init();
//             inputs.init();
//             formValidation.init();
//             ajaxForm.init();
//             slider.init();
//             retina.init();
//             tabs.init();
//             members.init();

//             if (bh('map').length > 0) {
//               map.init();
//             }

//             FastClick.attach(document.body);

//             scrollEvent.init();
//             loadEvent.init();
//             resizeEvent.init();

//             return {
//               init: init,
//             };
//           };
//       };
//       applicationJS();
//     });
//   });
// });
