export default () => {
  const covidq = document.getElementById('travel_health_covid_vaccination')

  if (covidq) {
    covidq.addEventListener('change', (event) => {
      console.log('heeeeey');
      const details = document.querySelector(
        `div[data-behaviour="covid-details"]`
      );

      if (event.target.value === 'Yes' || event.target.value === 'Medically Exempt') {
        details.classList.remove('hidden');
      } else {
        details.classList.add('hidden');
      }
    });
  }
};
