export default () => {
  // const parallaxEl = [...document.querySelectorAll(`[data-parallax="true"]`)];
  const parallaxEl = document.getElementById('parallax-background');

  if (!parallaxEl) return;

  window.addEventListener('scroll', () => {
    let scrolledHeight = window.pageYOffset,
      limit = parallaxEl.offsetTop + parallaxEl.offsetHeight;

    if (scrolledHeight > parallaxEl.offsetTop && scrolledHeight <= limit) {
      parallaxEl.style.backgroundPositionY =
        (scrolledHeight - parallaxEl.offsetTop) / 1.5 + 'px';
    } else {
      parallaxEl.style.backgroundPositionY = '0';
    }

    console.log(scrolledHeight);
  });

  // var parallaxEl = $('[data-parallax=true]');
  // function init() {
  //   parallaxEl.each(function () {
  //     $(this).parallax();
  //   });
  // }

  // // return {
  // //   init: init,
  // // };
  // init();
};
