export default () => {
  const button = document.getElementById('show-hide-trigger');
  const show = document.querySelector('.show-hide');

  if (button) {
    button.addEventListener('click', () => {
      button.classList.toggle('open');
      show.classList.toggle('open-show');
    });
  }

  // function init() {
  //   bh('show-hide-trigger').on('click', function () {
  //     var $this = $(this);
  //     $this.toggleClass('open');
  //     $('#' + $this.attr('data-section')).toggleClass('open');
  //     $(
  //       '[data-content-section=' + $this.attr('data-section') + ']'
  //     ).toggleClass('open');
  //     return false;
  //   });
  // }

  // return {
  //   init: init,
  // };
};
