export default () => {
  const radios = document.querySelector(`[data-behaviour="condition-radio"]`)

  if (radios) {
    radios.forEach((input) => {
      input.addEventListener('change', (event) => {
        const details = document.querySelector(
          `div[data-condition="${event.target.dataset.condition}"]`
        );

        if (event.target.value === 'true') {
          details.classList.remove('hidden');
        } else {
          details.classList.add('hidden');
        }
      });
    });
  }
};
